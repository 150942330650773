<template>
    <div :class="{sticky}">
        <v-toolbar flat :elevation="sticky ? 1 : 0" :color="toolbarColor" :prominent="!small" :extension-height="extensionHeight">
            <v-app-bar-nav-icon v-if="!hideNavbtn" @click="toggleDrawer()"></v-app-bar-nav-icon>
            <v-btn absolute class="ml-6" style="margin-top: 5px" text v-if="backTo" @click="$router.push(backTo)">
                <v-icon left>keyboard_backspace</v-icon>
                {{ backToText || 'Back' }}</v-btn
            >
            <v-toolbar-title>
                <v-icon left v-if="icon" :class="{'flip-x':flipIcon}">{{ icon }}</v-icon>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template #extension v-if="extended">
                <slot></slot>
            </template>
        </v-toolbar>
        <v-sheet :color="sheetColor" v-if="!extended">
            <slot></slot>
        </v-sheet>
    </div>
</template>

<script>
import EventBus from '@/lib/EventBus.ts';
export default {
    props: {
        title: String,
        icon: String,
        toolbarColor: String,
        sheetColor: {
            type: String,
            default: 'transparent',
        },
        extended: {
            type: Boolean,
            default: false,
        },
        extensionHeight: Number,
        hideNavbtn: Boolean,
        small: Boolean,
        backTo: String,
        backToText: String,
        sticky: Boolean,
        flipIcon: Boolean,
        needsFilters: Boolean
    },
    methods: {
        toggleDrawer() {
            EventBus.$emit('toggleDrawer');
        },
        toggleFiltersDrawer() {
            EventBus.$emit('toggleFilters');
        }
    },
};
</script>

<style scoped>
.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>