import moment from "@/lib/moment";
import {
    Granularity,
    ISpendAndConsumptionGroup,
    ISpendParameters,
    ISpendResult,
    ISpendUsageTableItemViewModel,
    ISpendUsageTableRecordsViewModel,
    ISpendUsageTableSlotViewModel,
    PluralEntityType,
} from "@/models";
import { ISpendGroupEntityReference } from "@/models/ISpendResult";
import { SpendUsageTableConstants } from "@/models/ISpendUsageTable";

export class SpendUsageTableFunctions {
    static convertToItemRecords(
        params: ISpendParameters,
        segregateBy: PluralEntityType | PluralEntityType[],
        response: ISpendResult<ISpendAndConsumptionGroup>,
        costView = "Actual",
    ): ISpendUsageTableRecordsViewModel {
        const monthSlots = SpendUsageTableFunctions.slotsForMonthColumns(
            params,
        );
        const dailySlots = SpendUsageTableFunctions.slotsForDailyColumns(
            params,
        );
        const records = [];
        for (const spendResultGroup of response.groupings) {
            const entities: ISpendGroupEntityReference[] =
                spendResultGroup.entities && spendResultGroup.entities.length
                    ? spendResultGroup.entities
                    : [{
                        name: spendResultGroup.name,
                        id: spendResultGroup.id,
                        type: Array.isArray(segregateBy) ? segregateBy.join(',') : segregateBy,
                    }];
            const record: ISpendUsageTableItemViewModel | {
                [key: string]: number;
            } = {
                total: spendResultGroup.total,
                isOther: spendResultGroup.isOther,
                totalConsumption: spendResultGroup.totalConsumption || 0,
                unit: spendResultGroup.unit || "<NA>",
                entities,
                timestamp: moment.utc(spendResultGroup.minDate).toDate()
                    .getTime(),
                costView,
            };
            for (const entity of entities) {
                record[`e_${entity.type}`] = entity.name;
            }
            for (const slot of monthSlots) {
                const monthlyTotalsForYear =
                    spendResultGroup.monthlyCharges[slot.year];
                const monthlyConsumptionForYear =
                    (spendResultGroup.monthlyConsumptionTotals || {})[
                        slot.year
                    ] || {};
                if (monthlyTotalsForYear) {
                    record[slot.costValuePropName] =
                        monthlyTotalsForYear[slot.month] || 0;
                    record[
                        slot.costValuePropName +
                        SpendUsageTableConstants.consumptionKeySuffix
                    ] = monthlyConsumptionForYear[slot.month] || null;
                } else {
                    record[slot.costValuePropName] = 0;
                    record[
                        slot.costValuePropName +
                        SpendUsageTableConstants.consumptionKeySuffix
                    ] = null;
                }
            }
            if (dailySlots.length) {
                for (const usageRecord of spendResultGroup.usageRecords) {
                    const urDate = moment.utc(usageRecord.usageDate).format(
                        SpendUsageTableConstants.slotDateFormat,
                    );
                    record[`day${urDate}`] = usageRecord.charges;
                    record[
                        `day${urDate}${SpendUsageTableConstants.consumptionKeySuffix}`
                    ] = usageRecord.unitsConsumed;
                }
            }
            records.push(record);
        }

        return { monthSlots, dailySlots, records };
    }

    static slotsForDailyColumns(
        params: ISpendParameters,
    ): ISpendUsageTableSlotViewModel[] {
        if (params.granularity !== Granularity.daily) return [];

        const fromDate = moment.utc(params.fromDate);
        const toDate = moment.utc(params.toDate);
        const dates = [];
        for (
            let tempDate = moment(fromDate);
            tempDate.isBefore(toDate);
            tempDate = tempDate.add(1, "days")
        ) {
            dates.push(tempDate);
        }
        dates.push(toDate);
        return dates.map((date) => {
            const slotKey = date.format(
                SpendUsageTableConstants.slotDateFormat,
            );
            const year = date.format("YYYY");
            const month = date.format("MM");
            const day = date.format("DD");
            return {
                header: date.format("DD/MM/YYYY"),
                // Keys for navigating the API response:
                year,
                month,
                day,
                // Vue data table slots:
                itemSlotName: `item.day${slotKey}`,
                costValuePropName: `day${slotKey}`,
                unitValuePropName:
                    `day${slotKey}${SpendUsageTableConstants.consumptionKeySuffix}`,
            };
        });
    }

    static slotsForMonthColumns(
        params: ISpendParameters,
    ): ISpendUsageTableSlotViewModel[] {
        if (params.granularity !== Granularity.monthly) return [];
        const fromDate = moment.utc(params.fromDate);
        const toDate = moment.utc(params.toDate);
        const fromMonth = moment.utc(params.fromDate).startOf("month");
        const toMonth = moment.utc(params.toDate).startOf("month");

        const slots = [];
        let date = fromMonth;
        for (let i = 0; !date.isAfter(toMonth); ++i) {
            const monthYear = date.format("MMM YYYY");
            const endOfMonth = date.endOf("month");

            let partialFromDayOfMonth: string;
            if (fromDate.isAfter(date, "day")) {
                // The filter from date is after the start of this month.
                partialFromDayOfMonth = fromDate.format("D");
            }

            let partialToDayOfMonth;
            if (toDate.isBefore(endOfMonth, "day")) {
                // The filter to date is before the end of this month.
                partialToDayOfMonth = toDate.format("D");
            }

            let header: string;
            if (partialFromDayOfMonth && partialToDayOfMonth) {
                // This month is partial on both sides.
                header =
                    `${partialFromDayOfMonth}-${partialToDayOfMonth} ${monthYear}`;
            } else if (partialFromDayOfMonth) {
                // This month is partial, starting somewhere in the middle.
                header = `${partialFromDayOfMonth}-${
                    endOfMonth.format("D")
                } ${monthYear}`;
            } else if (partialToDayOfMonth) {
                // This month is partial, ending somewhere in the middle.
                header = `${
                    date.format("D")
                }-${partialToDayOfMonth} ${monthYear}`;
            } else {
                // This is a full month's worth of charges.
                header = monthYear;
            }

            const slot = {
                header,
                // Keys for navigating the API response:
                year: date.format("YYYY"),
                month: date.format("MM"),
                // Vue data table slots:
                itemSlotName: `item.month${i}`,
                itemValuePropName: `month${i}`,
                costValuePropName: `month${i}`,
            };
            slots.push(slot);
            date = date.add(1, "M");
        }
        return slots;
    }
}
